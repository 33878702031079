import React, { useState } from 'react';
import { db } from '../auth/firebase';
import { auth } from '../auth/firebase'; // Make sure to import auth from your firebase configuration
import '../pages/dataList.css';
const tinyid = require('tiny-unique-id');

function Input() {
    const [input, setInput] = useState('');
    const [shorten, setShorten] = useState('');
    const [title, setTitle] = useState('');

    const handleDb = async (e) => {
        e.preventDefault();
        const slug = tinyid.unique();

        // Get the current authenticated user
        const user = auth.currentUser;

        await db.collection('urls').add({
            title: title,
            url: input,
            slug: slug,
            userId: user ? user.uid : null, // Add the authenticated user's ID
            createdAt: new Date() // Optional: Add a timestamp for when the URL was created
        });

        setShorten(`${window.location.origin}/${slug}`);
    };

    return (
        <form className='data-display p-3'>
            <h2 className='mt-1'>Alfa URL Shortener</h2>
            <center>
                <div className='mt-4'>
                    <input type="text" disabled className='form-control' value={shorten} />
                    <input type="text" className='form-control mt-3' placeholder='Title' value={title} onChange={e => setTitle(e.target.value)} />
                    <input required type="url" className='form-control mt-3' placeholder='Enter URL' value={input} onChange={e => setInput(e.target.value)} />
                    <button onClick={handleDb} className='btn btn-success mt-3' disabled={!title || !input}>Go!</button>
                </div>
            </center>
        </form>
    );
}

export default Input;